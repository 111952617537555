<template>
  <div class="limtedCover">
    <img :src="resdata.img" alt="111" />
  </div>
</template>
        
<script >
export default {
  props: ["resdata"],
};
</script>
       
<style >
.limtedCover {
  width: 289px;
  height: 383px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 0px 20px 0px;
  overflow: hidden;
}
.limtedCover img {
  width: 100%;
  height: 100%;
}
</style>